export const getGeoLocation = () =>
    new Promise<string>((resolve, reject) => {
        resolve("");
        return;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve(`${latitude},${longitude}`);
                },
                (error) => console.log(error)
            );
        } else console.log("Geolocation is not supported by this browser.");

        resolve("");
    });

export const getDeviceId = () => navigator.userAgent;

export const getBrowser = () =>
    navigator.userAgent.match(
        /(chrome|firefox|msie|trident|edge(?=\/))\/?\s*(\d+)/i
    );
