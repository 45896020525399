import { getBrowser, getDeviceId, getGeoLocation } from "./utils";

const baseUrl: string = "https://d3efgo1i84dphq.cloudfront.net/collect";

interface IConfiguration {
    baseBody: {
        st_aid: string; // The application identifier.
        st_eid: string; // The experience identifier.
    };
    appKey: string;
}

export const insightsLog = async (
    config: IConfiguration,
    eventName: string,
    data: any = {}
) => {
    const loc_pnt = await getGeoLocation();

    const body = {
        ...config.baseBody, // Base values.
        ...data, // Specific event.

        en: eventName,

        ts_cre: new Date().toString(),
        de_id: getDeviceId(),
        pl_br: getBrowser()?.[0],
        loc_pnt,
    };

    fetch(baseUrl, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${config.appKey}`,
            "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(body),
    })
        .then((response) => response.text())
        .then(console.log)
        .catch(console.log);
};
